// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/samlarsen-disney/Documents/sld.codes/sld-memes/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-card-amex-js": () => import("/Users/samlarsen-disney/Documents/sld.codes/sld-memes/src/pages/card/amex.js" /* webpackChunkName: "component---src-pages-card-amex-js" */),
  "component---src-pages-card-index-js": () => import("/Users/samlarsen-disney/Documents/sld.codes/sld-memes/src/pages/card/index.js" /* webpackChunkName: "component---src-pages-card-index-js" */),
  "component---src-pages-index-js": () => import("/Users/samlarsen-disney/Documents/sld.codes/sld-memes/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

